// https://dev.to/dalezak/reusable-turbo-ios-project-configured-entirely-from-your-rails-app-3021
export default class Bridge {
  // Called from TurboController.swift#checkAuthenticationToken
  static connectDevice(uuid, token) {
    var controller = document.getElementById("device-registration");

    // Adding this div to the 'controller' element will trigger device_controller.js#deviceTargetConnected
    var div = document.createElement("div");
    div.setAttribute("data-device-target", "device");
    div.setAttribute("data-device-uuid-value", uuid);
    div.setAttribute("data-device-token-value", token);
    controller.appendChild(div);
  }

  static becomeActive() {
    var controller = document.getElementById("map-controller");

    // Adding this div to the 'controller' element will trigger map_controller.js#reconnectionTargetConnected
    var div = document.createElement("div");
    div.setAttribute("data-map-target", "reconnection");
    controller.appendChild(div);
  }
}
