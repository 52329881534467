import "controllers"; // Stimulus

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

ActiveStorage.start();
// Assign window.Turbo for the native adapter (even if it's not used for anything else).
// https://github.com/hotwired/turbo-rails#installation
window.Turbo = Turbo;

import "javascripts"; // import from packs/javascripts/index.js

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
