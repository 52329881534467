// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();

// enable Stimulus debug mode in development
Stimulus.debug = process.env.NODE_ENV === "development";

const context = require.context("controllers", true, /_controller\.js$/);
Stimulus.load(definitionsFromContext(context));

// https://github.com/excid3/tailwindcss-stimulus-components
import { Dropdown } from "tailwindcss-stimulus-components";
Stimulus.register("dropdown", Dropdown);
