import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";
import { getMetaValue, setMetaValue } from "helpers";

export default class extends Controller {
  static values = {
    registrationUrl: String,
    redirectUrl: String,
  };

  connect() {
    if (window.webkit) {
      // Call to the iOS app handled by TurboController: WKScriptMessageHandler
      window.webkit.messageHandlers.iOS.postMessage({
        "connect-device": true,
      });
    }
  }

  // Element created via bridge.js#connectDevice()
  deviceTargetConnected(element) {
    const uuid = element.getAttribute("data-device-uuid-value");
    const token = element.getAttribute("data-device-token-value");

    if (uuid && token) {
      setMetaValue("X-Authentication-ID", uuid);
      setMetaValue("X-Authentication-Token", token);
      this.renderSuccess();
    } else {
      this.generateAuthenticationToken();
    }
  }

  // private

  // Send the device id to the Rails app to generate a token
  async generateAuthenticationToken() {
    const request = new FetchRequest("post", this.registrationUrlValue);
    const response = await request.perform();
    if (response.ok) {
      const uuid = response.headers.get("X-Authentication-ID");
      const token = response.headers.get("X-Authentication-Token");

      setMetaValue("X-Authentication-ID", uuid);
      setMetaValue("X-Authentication-Token", token);

      if (window.webkit) {
        // Send the UUID and token back to the iOS app
        window.webkit.messageHandlers.iOS.postMessage({
          "X-Authentication-ID": uuid,
          "X-Authentication-Token": token,
        });

        // Unable to uset Turbo.visit as the scroll position is lost
        // https://github.com/hotwired/turbo/issues/37
        // Turbo.visit(
        //   `${this.redirectUrlValue}?device_uuid=${uuid}&device_token=${token}`,
        //   { action: "replace" }
        // );
        this.renderSuccess();
      }
    }
  }

  async renderSuccess() {
    const response = await get(this.redirectUrlValue, {
      headers: {
        "X-Authentication-ID": getMetaValue("X-Authentication-ID"),
        "X-Authentication-Token": getMetaValue("X-Authentication-Token"),
      },
      responseKind: "turbo-stream",
    });

    // If a 401 unauthenticated is returned, request a new token
    if (response.unauthenticated) {
      this.generateAuthenticationToken();
    }
  }
}
