// Managed by yarn

// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-483402699
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import "@maptiler/leaflet-maptilersdk";

// fix default marker issues
// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-483402699
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import Bridge from "./bridge.js";
window.bridge = Bridge;

import "dropzone/dist/min/dropzone.min.css";

// fontawesome
import "@fortawesome/fontawesome-free/js/solid.js";
import "@fortawesome/fontawesome-free/js/fontawesome.js";

// Custom scss
import "../stylesheets/application.scss";
import "../stylesheets/public.scss";
import "../stylesheets/map.scss";
import "../stylesheets/dashboard.scss";
import "../stylesheets/profile.scss";
