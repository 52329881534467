import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    fetch(this.data.get("url"))
      .then((response) => response.text())
      .then((html) => {
        (this.hasOutputTarget ? this.outputTarget : this.element).innerHTML =
          html;
      });
  }
}
