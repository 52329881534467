import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import { getMetaValue } from "helpers";
import { MaptilerLayer } from "@maptiler/leaflet-maptilersdk";

// Determines if the iOS app is available.
const webkit = window.webkit;

// The leaflet js map object
let map;
// Used to keep track of the markers on the map
const markers = {};

export default class extends Controller {
  static targets = [
    "output",
    "startLocationServicesBtn",
    "locationsContainer",
    "location",
  ];
  static values = {
    offset: Number,
    deviceId: Number,
    locationsPath: String,
    initialZoom: { type: Number, default: 15 },
    latitude: String,
    longitude: String,
    targetDevice: String,
  };
  static classes = ["active"];

  connect() {
    this.initaliseMap();
    this.loadLocations();
    if (webkit) {
      this.initLocationServices();
      this.startLocationServices();
    }
  }

  disconnect() {
    if (webkit) {
      this.pauseLocationServices();
    }
  }

  reconnectionTargetConnected(element) {
    this.resize();
    this.loadLocations();
  }

  locationTargetConnected(element) {
    if (this.isOwnLocation(element) && !this.isLocationServicesEnabled()) {
      this.startLocationServicesBtnTarget.classList.add(this.activeClass);
    }
    this.refreshMarker(element);
  }

  isOwnLocation(element) {
    return element.getAttribute("data-device-id") == this.deviceIdValue;
  }

  ownLocation() {
    return this.locationTargets.find((location) =>
      this.isOwnLocation(location)
    );
  }

  resize() {
    this.outputTarget.style.height = `${
      window.visualViewport.height + this.offsetValue
    }px`;

    // catering for the top notch on ios devices
    window.scroll({
      top: this.offsetValue,
    });
  }

  isLocationServicesEnabled() {
    if (this.hasStartLocationServicesBtnTarget) {
      return this.startLocationServicesBtnTarget.classList.contains(
        this.activeClass
      );
    }
  }

  initLocationServices() {
    this.locationServices("init");
  }

  startLocationServices() {
    this.flyToCurrentLocation();
    if (!this.isLocationServicesEnabled()) {
      this.startLocationServicesBtnTarget.classList.add(this.activeClass);
      this.locationServices("start");
    }
  }

  pauseLocationServices() {
    this.locationServices("pause");
    this.startLocationServicesBtnTarget.classList.remove(this.activeClass);
  }

  stopLocationServices() {
    this.locationServices("stop");
    this.startLocationServicesBtnTarget.classList.remove(this.activeClass);
  }

  // Private

  isMapInitalised() {
    // FIXME: Determine why the map is not [re]initalised when navigating
    //        back to the map.
    return typeof map !== "undefined";
  }

  locationServices(action) {
    if (webkit) {
      const key = `${action}-location-services`;
      // Call to the iOS app handled by TurboController: WKScriptMessageHandler
      let payload = {};
      payload[key] = true;
      webkit.messageHandlers.iOS.postMessage(payload);
    }
  }

  initaliseMap() {
    this.resize();

    if (!this.isMapInitalised()) {
      const mapID = this.outputTarget.id;

      // Base layer - open street map - https://www.openstreetmap.org - default
      const standard = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          id: mapID,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">Open Street Map</a>',
        }
      );

      // Base layer - open street map FR - includes sports (slow)
      const fr = L.tileLayer(
        "https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
        {
          id: mapID,
          attribution:
            '&copy; <a href="https://www.openstreetmap.fr/mentions-legales/">Open Street Map France</a>',
        }
      );

      const topo = L.tileLayer(
        "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
        {
          id: mapID,
          attribution:
            '&copy; <a href="https://opentopomap.org">Open Topo Map</a>',
        }
      );

      const key = "vLi2An3QWCaNdZw4mMwx";

      const topo_v2 = new MaptilerLayer({
        apiKey: key,
        style: "topo-v2",
      });

      const winter = new MaptilerLayer({
        apiKey: key,
        style: "winter",
      });

      const satellite = new MaptilerLayer({
        apiKey: key,
        style: "satellite",
      });

      // Overlaid with Open Snow Map - https://www.opensnowmap.org
      const snowmap = L.tileLayer(
        "https://tiles.opensnowmap.org/pistes/{z}/{x}/{y}.png",
        {
          id: mapID,
          attribution:
            '&copy; <a href="https://www.opensnowmap.org">OpenSnowMap</a>',
        }
      );

      // http://www.waymarkedtrails.org/
      const directions = L.tileLayer(
        "https://tile.waymarkedtrails.org/slopes/{z}/{x}/{y}.png",
        {
          id: mapID,
          attribution:
            '&copy; <a href="https://www.waymarkedtrails.org">WayMarkedTrails</a>',
        }
      );

      const longitude = this.longitudeValue;
      const latitude = this.latitudeValue;

      // Initalise the map with a location, zoom level and the layers.
      map = L.map(this.outputTarget.id, {
        center: [latitude, longitude],
        zoom: this.initialZoomValue,
        minZoom: 3,
        layers: [topo_v2, snowmap],
      });

      // Specify the available layers...
      const baseMaps = {
        Topographic: topo_v2,
        Winter: winter,
        Satellite: satellite,
        "Open Street Map": standard,
        "Open Street Map FR": fr,
        "Open Topo Map": topo,
      };

      // ... and any overlays.
      const overlayMaps = {
        "Piste map": snowmap,
        "Piste directions": directions,
      };

      // Finally add the controls to the map.
      L.control.layers(baseMaps, overlayMaps).addTo(map);
    }
  }

  refreshMarker(location) {
    const ownLocation = this.isOwnLocation(location);
    const id = location.getAttribute("data-marker-id");
    const device_uuid = location.getAttribute("data-device-uuid");
    const label = location.getAttribute("data-marker-label");
    const state = location.getAttribute("data-state");
    const latitude = location.getAttribute("data-latitude");
    const longitude = location.getAttribute("data-longitude");
    const acquiredAt = location.getAttribute("data-acquired-at");
    const accuracyAcquired =
      location.getAttribute("data-accuracy-acquired") === "true";
    const flyTo = location.getAttribute("data-fly-to") === "true";

    if (ownLocation && markers.hasOwnProperty(id)) {
      // update the existing marker
      markers[id].setLatLng([latitude, longitude]).update();
      if (ownLocation) {
        if (flyTo) {
          this.flyToCurrentLocation();
        }
        // If the location received meets the accuracy requirement...
        if (accuracyAcquired) {
          // ... pause the location services
          this.pauseLocationServices();
        }
      }
    } else {
      // add a new marker to the map
      this.addMarker(
        id,
        latitude,
        longitude,
        ownLocation,
        label,
        state,
        device_uuid
      );
      if (device_uuid === this.targetDeviceValue) {
        this.flyTo(latitude, longitude, 10);
      }
    }

    if (ownLocation) {
      // Display the accuracy
      this.removeMarker(`${id}-accuracy`);

      const zoomLevel = map.getZoom();
      const accuracyDisplayed =
        location.getAttribute("data-accuracy-displayed") === "true";

      if (accuracyDisplayed && zoomLevel > 10) {
        const horizontalAccuracy = parseFloat(
          location.getAttribute("data-horizontal-accuracy")
        );
        markers[`${id}-accuracy`] = L.circle([latitude, longitude], {
          radius: horizontalAccuracy,
          interactive: false,
        }).addTo(map);
      }
    } else {
      markers[id].bindPopup("<span>" + acquiredAt + "</span>", {
        closeButton: false,
      });
    }
  }

  addMarker(id, latitude, longitude, ownLocation, label, state, device_uuid) {
    let icon;
    if (ownLocation) {
      icon = L.divIcon({
        className: "self",
        html: "<div class='map-marker'></div>",
        iconSize: [18, 18],
        iconAnchor: [9, 9],
      });
    } else {
      let className = "other";
      if (state) {
        className += " " + state;
      }
      if (device_uuid === this.targetDeviceValue) {
        className += " highlight";
      }
      icon = L.divIcon({
        className: className,
        html: `<div class='map-marker'></div><span class='map-marker-label'>${label}</span>`,
        iconSize: [30, 42],
        iconAnchor: [15, 42],
      });
    }

    this.removeMarker(id);
    markers[id] = L.marker([latitude, longitude], { icon: icon }).addTo(map);

    if (ownLocation) {
      this.flyTo(latitude, longitude, this.initialZoomValue);
    }
  }

  flyToCurrentLocation(zoom) {
    if (this.isMapInitalised() && this.ownLocation()) {
      let zoom = zoom || map.getZoom();
      const latitude = this.ownLocation().getAttribute("data-latitude");
      const longitude = this.ownLocation().getAttribute("data-longitude");
      this.flyTo(latitude, longitude, zoom);
    }
  }

  flyTo(latitude, longitude, zoom) {
    map.flyTo(new L.LatLng(latitude, longitude), zoom);
  }

  removeMarker(id) {
    if (markers.hasOwnProperty(id)) {
      map.removeLayer(markers[id]);
    }
  }

  async loadLocations() {
    const response = await get(this.locationsPathValue, {
      headers: {
        "X-Authentication-ID": getMetaValue("X-Authentication-ID"),
        "X-Authentication-Token": getMetaValue("X-Authentication-Token"),
      },
    });

    if (response.ok) {
      const body = await response.html;
      this.locationsContainerTarget.innerHTML = body;
    }
  }
}
